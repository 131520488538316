//
// The first three layers are stricly based on ITCSS!
//

// Settings
@import "../../../../../node_modules/@gymondo/frontend-core/css/00-settings/index";

// Tools
@import "../../../../../node_modules/@gymondo/frontend-core/css/01-tools/index";

// Generics
// This is the first layer that creates acutal CSS code
@import "../../../../../node_modules/@gymondo/frontend-core/css/02-generics/index";

// End of ITCSS

//
// Make sure that all general helper classes (eg program colors)
// are included *after* the atoms, because they will (or should) have
// the same specificity like the buttons for exmple
//

$milestoneSize: 40px;

.stepsWrapper {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: space-between;
  margin-bottom: gym-get-spacing(4xl);

  @media #{$media-xs-only} {
    padding: 0 gym-get-spacing(m);
    margin-bottom: gym-get-spacing(xxl);
  }

  .step {
    position: relative;
    background-color: gym-get-color('primary', 2);
    border-radius: 50%;
    width: $milestoneSize;
    height: $milestoneSize;
    text-align: center;

    &.active {
      background-color: gym-get-color('primary', 3);
    }

    .text {
      display: block;
      white-space: nowrap;
      position: absolute;
      top: $milestoneSize;
      left: 50%;
      margin-top: gym-get-spacing(xxs);
      transform: translate(-50%, 0);
    }
  }

}

.bar {
  background-color: gym-get-color('primary', 2);
  height: 8px;
  flex-grow: 1;
  position: relative;
  top: 16px;
  margin: 0 -1px;
  z-index: -1;

  &.active {
    background-color: gym-get-color('primary', 3);
  }

}

.priceWrapper {
  position: relative;
  width: 100%;

  .text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.infoGraphic {
  row-gap: gym-get-spacing(s);
}

.graphicCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    max-width: 20px;
  }
}

.striped {
  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    display: block;
    content: '';
    width: 100%;
    border-bottom: 2px gym-get-color(white) solid;
    transform: rotate(-30deg);
  }
}

.offerDetails {
  margin-top: gym-get-spacing(m);
  margin-bottom: gym-get-spacing(xl);


  .secondaryText {
    color: gym-get-color('secondary', 4);
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    max-width: 150px;
    border-radius: $border-radius;
    padding: gym-get-spacing(xxs);
    margin-top: gym-get-spacing(xxs);
    background-color: gym-get-color('secondary', 4);

    &.primaryBox {
      background-color: gym-get-color('primary', 3);

    }
  }
}